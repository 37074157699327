<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
import store from "@/state/store";
export default {
  data() {
    return {
      user: [],
      name: "",
      email: "",
      phone: "",
      userId: store.getters["user/id"],
      appointments: [],
      prescription: [],
      isActive: false,
      showPass: false
    };
  },
  methods: {
    async UserDetails() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/user/" + this.userId
      );
      this.user = response.data;
      this.name = this.user.name;
      this.email = this.user.email;
      this.phone = this.user.phone;
    },
    async getAppointments() {
      const response = await axios.get(
        " https://api.doctosoft.com/index.php/api/user/appointment/" +
        this.userId
      );
      this.appointments = response.data;
    },

    async getHistory() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/user/medical/history/" +
        this.userId
      );
      this.prescription = response.data;
      console.log(this.prescription.doctor)
    },

    async handleSubmit() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.user.name);
      urlencoded.append("password", this.user.password);
      urlencoded.append("email", this.user.email);
      urlencoded.append("phone", this.user.phone);
      // urlencoded.append("ip", this.user.ip);
      await axios.put(
        " https://api.doctosoft.com/index.php/api/user/" + this.userId,
        urlencoded
      );
      this.UserDetails();
    },
    toggleShowPassword() {
      this.showPass = !this.showPass;
    },
  },
  components: { Navbar, Footer },
  created() {
    this.UserDetails();
    this.getAppointments();
    this.getHistory();
  },
  computed: {
    passType() {
      return this.showPass ? "text" : "password";
    },
  }
};
</script>

<template>
  <Navbar />
  <div class="profile-foreground position-relative">
    <div class="profile-wid-bg">
      <img src="@/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
    </div>
  </div>
  <div class="pt-4 mb-4 mb-lg-3 pb-lg-4 profile container-fluid">
    <b-row class="g-4">
      <b-col cols="auto">
        <div class="avatar-lg">
          <div class="avatar-title rounded-circle bg-danger userprofile">
            {{ name.charAt(0) }}
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="p-2">
          <h3 class="text-white mb-1">{{ name }}</h3>
          <div class="hstack text-white-50 gap-1">
            <div class="me-2">
              <i class="ri-mail-line me-1 text-white-75 fs-16 align-middle"></i>{{ email }}
            </div>
          </div>
          <div class="hstack text-white-50 gap-1" v-if="phone">
            <div class="me-2">
              <i class="ri-phone-line me-1 text-white-75 fs-16 align-middle"></i>{{ phone }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
  <div class="d-flex container-fluid">
    <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
      <li class="nav-item">
        <b-link class="nav-link fs-14 active" data-bs-toggle="tab" href="#profile-tab" role="tab">
          <i class="ri-airplay-fill d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Profile-info</span>
        </b-link>
      </li>
      <li class="nav-item">
        <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#appointment-tab" role="tab">
          <i class="ri-list-unordered d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Appointments</span>
        </b-link>
      </li>
      <li class="nav-item">
        <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#chat" role="tab">
          <i class="ri-price-tag-line d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Chat</span>
        </b-link>
      </li>
      <li class="nav-item">
        <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#history" role="tab">
          <i class="ri-price-tag-line d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">History</span>
        </b-link>
      </li>
    </ul>
  </div>
  <div class="tab-content pt-4 text-muted">
    <div class="tab-pane active" id="profile-tab" role="tabpanel">
      <b-row>
        <b-col xxl="12">
          <b-card no-body>
            <b-card-body class="container ">
              <div class="live-preview">
                <form @submit.prevent="handleSubmit">
                  <b-row>
                    <b-col md="12">
                      <div class="mb-3">
                        <label for="firstNameinput" class="form-label">Name
                        </label>
                        <input type="text" class="form-control" id="firstNameinput" placeholder="Your Name"
                          v-model="user.name" />
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="mb-3">
                        <label for="lastNameinput" class="form-label">Email</label>
                        <input type="text" class="form-control" id="lastNameinput" placeholder="Your Email"
                          v-model="user.email" />
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="mb-3">
                        <label for="compnayNameinput" class="form-label">Password</label>
                        <input :type="passType" class="form-control" id="compnayNameinput" placeholder="Your password"
                          v-model="user.password" />
                        <b-button variant="link" class="pform-control" type="button" id="password-addon"
                          @click="toggleShowPassword">
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="mb-3">
                        <label for="phonenumberInput" class="form-label">Phone Number
                        </label>
                        <input type="tel" class="form-control" id="phonenumberInput" placeholder="+(245) 451 45123"
                          v-model="user.phone" />
                      </div>
                    </b-col>
                    <b-col lg="12">
                      <div class="text-center">
                        <b-button type="submit" variant="primary">
                          Submit
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="tab-pane fade" id="appointment-tab" role="tabpanel">
      <b-row>
        <b-col xxl="12">
          <b-card no-body>
            <b-card-body>
              <div class="container">
                <b-row>
                  <b-col lg="4" no-body v-for="appointment in appointments" :key="appointment.id">
                    <b-card class="appointment-card" :class="{
                      grey: appointment.status === 'pending' || 'new',
                      red: appointment.status === 'reject',
                      green: appointment.status === 'accept',
                    }">
                      <!-- <b-card-body> -->
                      <div class="time-div">
                        <h3>{{ appointment.status }}</h3>
                        <h2>{{ appointment.date }}</h2>
                        <p>{{ appointment.time }}</p>
                      </div>
                      <div class="data-div">
                        <h4 v-if="appointment.reason">
                          Reason: {{ appointment.reason }}
                        </h4>
                        <h4>Doctor: {{ appointment.doctor }}</h4>
                      </div>
                      <!-- </b-card-body> -->
                    </b-card>
                  </b-col>
                </b-row>
              </div>
          </b-card-body>
        </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="tab-pane fade padding-control" id="chat" role="tabpanel">
      <b-row>
        <b-col xxl="12">
          <b-card no-body class="padding-control">
            <b-card-body class="container-fluid padding-control">
              <div class="live-preview chat-div">
                <div class="chat-leftsidebar">
                  <div class="px-4 pt-4 mb-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-grow-1">
                        <h5 class="mb-4">Chats</h5>
                      </div>
                      <div class="flex-shrink-0"></div>
                    </div>

                  </div>

                  <div class="chat-room-list" data-simplebar>
                    <div class="d-flex align-items-center px-4 mb-2">
                      <div class="flex-grow-1">
                        <h4 class="mb-0 fs-11 text-muted text-uppercase">
                          Direct Messages
                        </h4>
                      </div>
                    </div>

                    <div class="chat-message-list">
                      <SimpleBar class="list-unstyled chat-list chat-user-list">
                        <li @click="isActive = !isActive" :class="{ active: isActive }">
                          <b-link href="#vikas">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                <div class="avatar-xxs">
                                  <div class="avatar-title rounded-circle bg-danger userprofile">
                                    v
                                  </div>
                                </div>
                              </div>
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-truncate mb-1">
                                  {{ user.name }}
                                </p>
                              </div>

                              <!-- <div class="flex-shrink-0">
                        <b-badge variant="soft-dark" class="badge-soft-dark rounded p-1">9:00</b-badge>
                      </div> -->
                            </div>
                          </b-link>
                        </li>
                        <li>
                          <b-link href="#raj">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                <div class="avatar-xxs">
                                  <div class="avatar-title rounded-circle bg-danger userprofile">
                                    R
                                  </div>
                                </div>
                              </div>
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-truncate mb-1">Raj</p>
                              </div>

                              <!-- <div class="flex-shrink-0">
                        <b-badge variant="soft-dark" class="badge-soft-dark rounded p-1">9:00</b-badge>
                      </div> -->
                            </div>
                          </b-link>
                        </li>
                      </SimpleBar>
                    </div>
                  </div>
                </div>
                <div class="w-100 user-chat-default right-chat">
                  <div class="p-3 user-chat-topbar">
                    <b-row class="align-items-center">
                      <b-col sm="4" cols="8">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 n">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                <img :src="
                                  profile
                                    ? profile
                                    : require('@/assets/images/users/user-dummy-img.jpg')
                                " class="rounded-circle avatar-xs" alt="profile" />
                                <!-- <span class="user-status"></span> -->
                              </div>
                              <div class="flex-grow-1 overflow-hidden">
                                <h5 class="text-truncate mb-0 fs-16">
                                  <b-link class="text-reset username" data-bs-toggle="offcanvas"
                                    href="#userProfileCanvasExample" aria-controls="userProfileCanvasExample">Rj
                                  </b-link>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="position-relative ">
                    <div class="chat-conversation p-3 p-lg-4" id="chat-conversation" data-simplebar ref="current">
                      <ul class="list-unstyled chat-conversation-list">
                        <li class="chat-list right">
                          <div class="conversation-list">
                            <div class="chat-avatar">
                              <img :src="
                                profile
                                  ? profile
                                  : require('@/assets/images/users/user-dummy-img.jpg')
                              " alt="" />
                            </div>
                            <div class="user-chat-content">
                              <div class="ctext-wrap">
                                <div class="ctext-wrap-content">
                                  <p class="mb-0 ctext-content">
                                    Hello from patient
                                  </p>
                                </div>

                                <div class="conversation-name">
                                  <!-- <small class="text-muted time">{{ e.date}}</small> -->
                                  <span class="text-success check-message-icon"><i
                                      class="ri-check-double-line align-bottom"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="chat-list">
                          <div class="conversation-list">
                            <div class="chat-avatar">
                              <img :src="
                                profile
                                  ? profile
                                  : require('@/assets/images/users/user-dummy-img.jpg')
                              " alt="" />
                            </div>
                            <div class="user-chat-content">
                              <div class="ctext-wrap">
                                <div class="ctext-wrap-content">
                                  <p class="mb-0 ctext-content">
                                    Hi from Static
                                  </p>
                                </div>

                                <div class="conversation-name">
                                  <!-- <small class="text-muted time">{{ e.date }}</small> -->
                                  <span class="text-success check-message-icon"><i
                                      class="ri-check-double-line align-bottom"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-input-section p-3 p-lg-4">
                    <form @submit.prevent="formSubmit">
                      <b-row class="g-0 align-items-center">
                        <b-col>
                          <div class="chat-input-feedback">
                            Please Enter a Message
                          </div>

                          <input type="text" class="form-control chat-input bg-light border-light"
                            placeholder="Enter Message..." />
                        </b-col>
                        <b-col cols="auto">
                          <div class="chat-input-links ms-2">
                            <div class="links-list-item">
                              <b-button variant="success" type="submit" class="chat-send">
                                <i class="ri-send-plane-2-fill align-bottom"></i>
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </form>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="tab-pane fade" id="history" role="tabpanel">
      <b-row>
        <b-col xxl="12">
          <b-card no-body>
            <b-card-body>
              <div class="container">
                <b-row>
                  <h1>history</h1>
                  <!-- <b-col v-for="pre in prescription" :key="pre.id">
                    <h1>{{ pre.note }}</h1>
                  </b-col> -->
                </b-row>
              </div>
          </b-card-body>
        </b-card>
        </b-col>
      </b-row>
    </div>
  </div>

  <Footer />
</template>

<style scoped>
.chat-div {
  display: flex;
  overflow-y: auto;
}

div.padding-control {
  padding: 0;
}

.right-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mb-3 {
  position: relative;
}

.mb-3 button {
  position: absolute;
  top: 72%;
  transform: translateY(-50%);
  left: 1070px;
}

.user-chat-topbar {
  margin: 5px;
}

.chat-conversation {
  margin: 5px;
}

.chat-input-section {
  margin: 5px;
}

.grey {
  background-image: linear-gradient(180deg, #cccccc, white, white);
}

.green {
  background-image: linear-gradient(180deg, rgba(0, 178, 0, 0.4), white, white);
}

.red {
  background-image: linear-gradient(180deg, rgba(171, 0, 0, 0.4), white, white);
}

.card {
  margin-bottom: 0px;
}

.profile {
  overflow-x: hidden;
}

.appointment-card {
  padding: 0;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.appointment-card .time-div {
  margin-bottom: 20px;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 10px;
}

.appointment-card .time-div p {
  padding: 0;
  margin: 0;
}

a.nav-link {
  padding: 10px;
}

.tab-content.pt-4.text-muted {
  overflow-x: hidden;
}

button.btn.btn-primary.btn-md {
  padding: 10px 20px;
}

.user-chat-default {
  background-color: #f3f3f9;
}

.user-chat-topbar {
  position: relative;
}

.chat-conversation {
  overflow-y: auto;
}

.chat-conversation .conversation-list .ctext-wrap {
  gap: 10px;
}
</style>
